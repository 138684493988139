<template>
    <div>
        <div v-if="!selectAccount">
            <DxLoadIndicator
                    id="medium-indicator"
                    :height="20"
                    :width="20"
            />
            Загрузка
        </div>
        <div v-if="selectAccount" class="accordion" id="accordionAccount">
            <div class="card">
                <div class="card-header d-flex flex-column justify-content-start flex-sm-row justify-content-sm-between">
                    <div class="mb-0">
                        <button class="btn btn-link btn-block pl-0 text-left mb-2 mb-sm-0" type="button" @click="accountShowHide" data-toggle="collapse" data-target="#collapse-account" aria-expanded="true" aria-controls="collapse-account">
                            <strong><i class="fas fa-chevron-down mr-2" :class="{'fa-chevron-down': accountShow, 'fa-chevron-right': !accountShow}" id="accountChevron"></i>Балансовый счет</strong>
                        </button>
                    </div>
                    <DxSelectBox
                            :data-source="accounts"
                            :value="selectAccount.balanceAccountId"
                            display-expr="number"
                            value-expr="balanceAccountId"
                            @value-changed="changeAccount"
                    />
                </div>
                <div id="collapse-account" class="collapse" :class="{'show': accountShow}" aria-labelledby="heading-account" data-parent="#accordionAccount">
                    <div class="card-body">
                    <div class="row select-account">
                        <div class="col-12 col-md-4 d-flex flex-row flex-md-column justify-content-start">
                            <p class="mr-2">
                                Номер (БС):
                            </p>
                            <p class="item-text">
                                {{ selectAccount.number }}
                            </p>
                        </div>
                        <div class="col-12 col-md-4 d-flex flex-row flex-md-column justify-content-start">
                            <p class="mr-2">
                                Вид счета:
                            </p>
                            <p class="item-text" v-html="getAccountKind(selectAccount.accountKind)"></p>
                        </div>
                        <div class="col-12 col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-start">
                            <p class="mr-2">
                                Валюта (БС):
                            </p>
                            <p class="item-text">
                                {{ selectAccount.currencyName }}
                            </p>
                        </div>
                        <div class="col-12 col-md-4 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
                            <p class="mr-2">
                                Дата нач. сальдо:
                            </p>
                            <p class="item-text">
                                {{ selectAccount.dateBegBalance | moment("DD.MM.YYYY") }}
                            </p>
                        </div>
                        <div class="col-12 col-md-4 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
                            <p class="mr-2">
                                Нач. сальдо:
                            </p>
                            <p class="item-text">
                                {{ selectAccount.begBalance | formatMoney2 }}
                            </p>
                        </div>
                        <div class="col-12 col-md-4 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
                            <p class="mr-2">
                                Сумма (НВУ):
                            </p>
                            <p class="item-text">
                                {{ selectAccount.elementsAmount | formatMoney2 }}
                            </p>
                        </div>
                        <div class="col-12 col-md-4 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
                            <p class="mr-2">
                                Выставлено:
                            </p>
                            <p class="item-text">
                                {{ selectAccount.billAmount | formatMoney2 }}
                            </p>
                        </div>
                        <div class="col-12 col-md-4 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
                            <p class="mr-2">
                                Оплачено:
                            </p>
                            <p class="item-text">
                                {{ selectAccount.payAmount | formatMoney2 }}
                            </p>
                        </div>
                        <div class="col-12 col-md-4 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
                            <p class="mr-2">
                                Кон. сальдо:
                            </p>
                            <p class="item-text">
                                {{ selectAccount.endBalance | formatMoney2 }}
                            </p>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import jQuery from 'jquery';
    let $ = jQuery;
    import localStore from './store';
    import {DxLoadIndicator} from 'devextreme-vue/load-indicator';
    import DxSelectBox from 'devextreme-vue/select-box';

    export default {
        name: "FinancesAccounts",
        props: ['Agent', 'changeBalanceAccount'],
        components: {
            DxLoadIndicator,
            DxSelectBox
        },
        data() {
            return {
                accounts: null,
                selectAccount: null,
                accountShow: false
            }
        },
        mounted() {
            this.getAccounts();

            Vue.nextTick(function () {
                $('#accordionAccount').on('show.bs.collapse', function () {
                    localStore.dispatch('getAccountShow', true).then(() => {
                        this.accountShow = localStore.state.accountShow;
                    })
                });

                $('#accordionAccount').on('hide.bs.collapse', function () {
                    localStore.dispatch('getAccountShow', false).then(() => {
                        this.accountShow = localStore.state.accountShow;
                    })
                });
            });
        },
        methods: {
            getAccounts() {
                if(!localStore.state.account) {
                    localStore.dispatch('getAccounts', {tokenId: this.Agent.tokenId}).then(() => {
                        this.accounts = localStore.state.accounts;

                        this.accounts.forEach(account => {
                            if(account.isDefault) {
                                localStore.dispatch('getAccount', account).then(() => {
                                    this.selectAccount = account;
                                    this.changeBalanceAccount(this.selectAccount.balanceAccountId);
                                });
                            }
                        });
                    })
                } else {
                    this.selectAccount = localStore.state.account;
                    this.accounts = localStore.state.accounts;

                    this.accounts.forEach(account => {
                        if(account.balanceAccountId == this.selectAccount.balanceAccountId) {
                            localStore.dispatch('getAccount', account).then(() => {
                                this.selectAccount = account;
                                this.changeBalanceAccount(this.selectAccount.balanceAccountId);
                            });
                        }
                    });
                }

                if(this.accountShow != localStore.state.accountShow) {
                    this.accountShow = localStore.state.accountShow;
                }
            },
            getAccountKind(kind) {
                switch (kind) {
                    case 'baDebet':
                        return '<i class="fas fa-plus text-success"></i> Дебит';
                    case 'baCredit':
                        return '<i class="fas fa-minus text-danger"></i> Кредит';
                }

                return '';
            },
            changeAccount(e) {
                this.accounts.forEach(account => {
                    if(account.balanceAccountId == e.value) {
                        localStore.dispatch('getAccount', account).then(() => {
                            this.selectAccount = account;
                            this.changeBalanceAccount(this.selectAccount.balanceAccountId);
                        });
                    }
                });
            },
            accountShowHide() {
                if($('#accountChevron').hasClass('fa-chevron-right')) {
                    $('#accountChevron').removeClass('fa-chevron-right');
                    $('#accountChevron').addClass('fa-chevron-down');
                } else {
                    $('#accountChevron').removeClass('fa-chevron-down');
                    $('#accountChevron').addClass('fa-chevron-right');
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    .select-account {
        -ms-content-zooming: none;
        -webkit-tap-highlight-color: transparent;
        -webkit-text-size-adjust: none;
        -webkit-touch-callout: none;
        padding: 0;
        outline: 0;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
        color: #212529;
        font-weight: 400;
        font-size: 14px;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        line-height: 1.35715;

        p {
            margin-bottom: 0.5rem;
        }

        .item-text {
            border-style: none none dashed none;
            background: 0 0;
            border-bottom: 1px dashed #ced4da;
            border-radius: 0;
        }
    }
</style>
